import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3bcd191d")
const _hoisted_1 = { class: "ppt-view" }
_popScopeId()

import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "@/store/index";

import { loadMicroApp } from "qiankun";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { eventCenter, event } from "@evideo/frontend-utils";
import logger from "@evideo/logger";

import servers from "@/config/servers";
import config from "@/config/app.config";
import _ from "lodash";
import { setMicroAppMap } from "@/main";


export default _defineComponent({
  setup(__props) {

const pptLoading = ref(true);
const pptLoadTxt = ref("正在加载ppt...");
const pptContainer = ref();
const pptUrl = ref("");
const pptApp = ref<any>(null);
const fullScreen = ref(false);
const route = useRoute();
const router = useRouter();

const loadPPT = async () => {
  const env = JSON.stringify(process.env.RELEASE_ENV);
  let entryUrl = config[servers.pptist];
  if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
    entryUrl = localStorage.entryUrl;
  }
  logger.info("ppt app entry url:", entryUrl);
  eventCenter.sub(`teachPptPC-load-ok`, () => {
    eventCenter.sub(event.COMMON_FULL_SCREEN, (res: any) => {
      if (res.fullScreen) {
        fullScreen.value = true;
      } else {
        fullScreen.value = false;
      }
    });
    eventCenter.send(event.PPTIST_LOAD_PPT, { url: pptUrl.value }, () => {
      pptLoading.value = false;
    });
  });
  setTimeout(() => {
    pptLoading.value === true && (pptLoading.value = false);
  }, 30000);

  logger.info("ppt-view-token", store.state.token);
  pptApp.value = await loadMicroApp(
    {
      name: "teachPptPC",
      entry: entryUrl,
      container: "#ppt-view-container",
      props: {
        args: {
          token: store.state.token,
          appName: "teachPptPC",
          runtime: {
            playPptRule: {
              play: "demonstrate",
              stop: "preview",
            },
            state: {
              screenType: "preview",
              screening: true,
            },
          },
        },
        micrAppUrl: entryUrl,
      },
    },
    {
      sandbox: { experimentalStyleIsolation: true },
      $$cacheLifecycleByAppName: true,
    }
  );
};

const goBack = _.throttle(() => {
  logger.info("call android method[closeTeachMaterial] to go back");
  // window.WebViewJavascriptBridge &&
  //   window.WebViewJavascriptBridge.callHandler(
  //     "closeTeachMaterial", // native中注册时的key值
  //     null // native中注册方法中的传入的数据
  //   );
  logger.info("router push orther path");
  router.replace({
    path: "/blank",
  });
}, 5000);

onMounted(async () => {
  pptUrl.value = route.query.pptUrl as string;
  if (!pptUrl.value) {
    pptLoadTxt.value = "没有ppt";
    return;
  }
  await loadPPT();
});

onBeforeUnmount(async () => {
  if (pptApp.value) {
    if (pptApp.value.preUnmount) {
      await pptApp.value.preUnmount();
    }
    pptApp.value.unmount();
    logger.info("卸载成功");
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", { class: "ppt-loading" }, _toDisplayString(pptLoadTxt.value), 513), [
      [_vShow, pptLoading.value]
    ]),
    _withDirectives(_createElementVNode("div", {
      id: "ppt-view-container",
      ref: pptContainer,
      class: "ppt-container"
    }, null, 512), [
      [_vShow, !pptLoading.value]
    ]),
    _withDirectives(_createElementVNode("a", {
      class: "go-back",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(goBack) && _unref(goBack)(...args)))
    }, "返回", 512), [
      [_vShow, !fullScreen.value]
    ])
  ]))
}
}

})